import Service from "./Service";
import { API_URL } from "../config";

const settingResource = `${API_URL}/getSetting`;

export default {
  getShippingPrice() {
    return Service.get(`${settingResource}`, {}).then((response) => {
      return response.data.data;
      // console.log(response.data.data);
    });
  },
};
