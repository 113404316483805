import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../pages/index.vue";
import Login from "../pages/login.vue";
import Logout from "../pages/logout.vue";
// import Reservation from "../views/reservation.vue";
import Home from "../pages/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/bank-accounts",
    name: "bank-accounts",

    component: () => import("../pages/bank-accounts.vue"),
  },
  {
    path: "/policy",
    name: "policy",

    component: () => import("../pages/policy.vue"),
  },
  {
    path: "/contact-us",
    name: "contact-us",

    component: () => import("../pages/contact-us.vue"),
  },
  {
    path: "/about",
    name: "about",

    component: () => import("../pages/about.vue"),
  },
  {
    path: "/myOrders",
    name: "orders",

    component: () => import("../pages/myOrders.vue"),
  },
  {
    path: "/order/:id",
    name: "order",

    component: () => import("../pages/order/_id.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",

    component: () => import("../pages/checkout.vue"),
  },
  {
    path: "/masna",
    name: "masna",

    component: () => import("../pages/masna.vue"),
  },
  {
    path: "/branch",
    name: "branch",

    component: () => import("../pages/branch.vue"),
  },
  {
    path: "/online-payment/:paymentId",
    name: "OnlinePayment",

    component: () => import("../pages/OnlinePayment.vue"),
  },
  {
    path: "/verify-code",
    name: "verify",

    component: () => import("../pages/verify-code.vue"),
  },
  {
    path: "/payments/redirect/:payment_id",
    name: "payment",

    component: () => import("../pages/payment.vue"),
  },
  {
    path: "/paymentMethod",
    name: "paymentMethod",

    component: () => import("../pages/paymentMethod.vue"),
  },
  {
    path: "/thankYou/:orderId",
    name: "thankYou",

    component: () => import("../pages/thankYou.vue"),
  },
  {
    path: "/confirm",
    name: "confirm",

    component: () => import("../pages/confirm.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
