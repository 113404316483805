import aboutUsService from "./AboutUsService";
import CartService from "./CartService";
import ordersService from "./ordersService";
import productService from "./productService";
import RegisterService from "./RegisterService";
import CitiesServices from "./CitiesServices";
import bankService from "./bankService";
import SettingsService from "./SettingsService";

const services = {
  about: aboutUsService,
  product: productService,
  register: RegisterService,
  cart: CartService,
  orders: ordersService,
  cities: CitiesServices,
  bank: bankService,
  settings: SettingsService,
};

export const ServiceFactory = {
  get: (name) => services[name],
};
